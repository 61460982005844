<template>
  <div>
    <form @submit.prevent="fetchDropDetails">
      <div class="input-group mb-3">
        <span class="input-group-text">#</span>
        <input v-model="dropID" type="number" class="form-control" placeholder="Drop ID" aria-label="Drop ID">
      </div>
    </form>
    <div v-if="dropDetails && dropDetails !== undefined" class="card text-black">
      <h2 class="card-header">{{ dropDetails.collection_name.toUpperCase() }}</h2>
      <div class="card-body">
        <h5 class="text-start card-title mb-4"><u>Drop Details:</u></h5>
        <table class="table text-start mb-4">
          <thead>
            <tr>
              <th scope="col">Price</th>
              <th scope="col">Supply</th>
              <th scope="col">Claim Limit</th>
              <th scope="col">Start Time</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ dropDetails.price_amount.toFixed(2) }} {{ dropDetails.price_currency }}</td>
              <td>{{ dropDetails.max_claimable }}</td>
              <td>{{ dropDetails.account_limit }}<span v-if="dropDetails.account_limit_cooldown > 0"> every {{ dropDetails.account_limit_cooldown }}s</span></td>
              <td>{{ dropDetails.start_time_str }}</td>
            </tr>
          </tbody>
        </table>
        <div v-if="dropDetails.auth_required === 1" class="alert alert-warning" role="alert">
          <strong>AUTHENTICATED DROP</strong> - Make sure you are WL to be able to buy this drop
        </div>
        <div class="flex-container">
          <div class="form-check form-switch text-start mb-2">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="readyToBuy">
            <label class="form-check-label" for="flexSwitchCheckChecked">AutoBuy this drop (You will need {{ (dropDetails.price_amount * dropDetails.account_limit).toFixed(2) }} {{ dropDetails.price_currency }})</label>
          </div>
          <div v-if="readyToBuy">
            <svg v-if="success" xmlns="http://www.w3.org/2000/svg" height="36" width="36" fill="none" viewBox="0 0 24 24" stroke="#00A151">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" height="36" width="36" fill="none" viewBox="0 0 24 24" stroke="#DC0909">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </div>
        <vac v-if="readyToBuy" :end-time="dropDetails.start_time - 5000" :speed="1" @finish="handleAutobuy">
          <span
            slot="process"
            slot-scope="{ timeObj }"
            class="text-danger display-5">{{ `${timeObj.h}:${timeObj.m}:${timeObj.s}.${timeObj.ms}` }}</span>
          <span slot="finish" class="text-success display-5">Finished!</span>
        </vac>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'DropDetails',
  data() {
    return {
      dropID: 93261,
      dropDetails: null,
      readyToBuy: false,
      interval: null,
      success: false
    }
  },
  props: ['wax'],
  watch: {
    readyToBuy(newVal) {
      if (!newVal) {
        clearInterval(this.interval)
      }
    }
  },
  methods: {
    async fetchDropDetails() {
      const res = await axios.post('https://chain.wax.io/v1/chain/get_table_rows', {
        json: true,
        code: "neftyblocksd",
        scope: "neftyblocksd",
        table: "drops",
        lower_bound: this.dropID,
        upper_bound: this.dropID
      })

      const data = res.data.rows[0]
      data.start_time *= 1000
      data.start_time_str = new Date(data.start_time).toLocaleString()
      const listingPrice = data.listing_price.split(' ')
      data.price_amount = parseFloat(listingPrice[0])
      data.price_currency = listingPrice[1]
      data.account_limit = data.account_limit > 0 ? data.account_limit : 1

      this.dropDetails = data
    },
    async handleBuy() {
      try {
        const now = new Date()
        console.log('Transaction in progress...', now.toISOString());

        const result = await this.wax.api.transact({
          actions: [
            {
              account: 'neftyblocksd',
              name: this.dropDetails.auth_required === 1 ? 'claimwproof' : 'claimdrop',
              authorization: [{
                actor: this.wax.userAccount,
                permission: 'active',
              }],
              data: {
                amount: this.dropDetails.account_limit,
                claimer: this.wax.userAccount,
                country: 'FR',
                currency: '8,WAX',
                asset_ids: [1099598712707],
                drop_id: this.dropDetails.drop_id,
                intended_delphi_median: 0,
                referrer: 'NeftyBlocks'
              },
            }
          ]
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        })

        console.log(result);
        this.success = true
        clearInterval(this.interval)
      } catch (error) {
        console.log(error.message);
      }
    },
    handleAutobuy() {
      if (this.readyToBuy) {
        this.handleBuy()        
        this.interval = setInterval(() => {
          this.handleBuy()
        }, 250)
      }
    }
  }
}
</script>

<style scoped>
  .flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
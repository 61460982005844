<template>
  <div id="app" class="container">
    <img height="100" src="https://neftyblocks.com/_web_nuxt/img/nefty-blocks-logo-dark.e2b3add.svg" alt="NeftyBlocks"><br>
    <button v-if="!userAccount" @click="handleLogin" type="button" class="btn btn-info">Login</button>
    <template v-else>
      <p class="text-end text-info">Logged as {{ userAccount }}</p>
      <div class="grid-container">
        <DropDetails v-for="index in 4" :wax="wax"/>
      </div>
    </template>
  </div>
</template>

<script>
import * as waxjs from "@waxio/waxjs/dist";
import DropDetails from "./components/DropDetails.vue";

export default {
  name: 'App',
  components: { DropDetails },
  data() {
    return {
      wax: null,
      userAccount: null,
    }
  },
  methods: {
    async handleLogin() {
      this.userAccount = await this.wax.login()
      console.log('Logged as', this.userAccount)
    }
  },

  created() {
    this.wax = new waxjs.WaxJS({ rpcEndpoint: 'https://api-wax.eosauthority.com' })
  }
}
</script>

<style>
body {
  background: #333333;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  margin-top: 40px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 5rem;
}
</style>
